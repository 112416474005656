import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "../Providers/AuthProvider";
import { useDarkMode } from "../Providers/DarkModeContext";
import PrivateRoute from "./PrivateRoute";

const LoginPage = lazy(() => import("../pages/Authentication/LoginPage"));
const CustomerEnquiry = lazy(() => import("../pages/Customer/CustomerEnquiry"));
const CustomerListPage = lazy(() =>
  import("../pages/Customer/CustomerListPage")
);
const ManageCustomerAccount = lazy(() =>
  import("../pages/Customer/ManageCustomerAccount")
);
const OrderDetails = lazy(() =>
  import("../pages/OrderManagement/OrderDetails")
);
const OrderManagement = lazy(() =>
  import("../pages/OrderManagement/OrderManagement")
);
const OrderQuoteCreate = lazy(() =>
  import("../pages/OrderManagement/OrderQuoteCreate")
);
const OrderSettings = lazy(() =>
  import("../pages/OrderManagement/OrderSettings")
);
const OrganizationEnquiry = lazy(() =>
  import("../pages/Organization/OrganizationEnquiry")
);
const Products = lazy(() => import("../pages/Product/Products"));
const CapacityPage = lazy(() =>
  import("../pages/Production/Capacity/CapacityPage")
);
const Role = lazy(() => import("../pages/Roles/Role"));
const SalesView = lazy(() => import("../pages/SalesView/SalesView"));
const ProductionSchedule = lazy(() =>
  import("../pages/Scheduler/ProductionSchedule")
);
const UserDetails = lazy(() => import("../pages/User/UserDetails"));
const UserProfile = lazy(() => import("../pages/User/UserProfile"));
const ImportCustomerPage = lazy(() =>
  import("../pages/Customer/ImportCustomerPage")
);
const ImportEnquiryDetailsPage = lazy(() =>
  import("../pages/Customer/ImportEnquiryDetailsPage")
);
const ImportOrdersPage = lazy(() =>
  import("../pages/OrderManagement/ImportOrders/ImportOrdersPage")
);
const ConfigureProductGroupPage = lazy(() =>
  import("../pages/Product/ConfigureProductGroup/ConfigureProductGroupPage")
);
const TourPage = lazy(() => import("../pages/Tour/TourPage"));
const TicketPage = lazy(() => import("../pages/Ticket/TicketsPage"));
const ManageOrderScreen = lazy(() =>
  import("../pages/OrderManagement/ManageOrderScreen")
);
const PageNotFound = lazy(() => import("../pages/PageNotFound"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const ProductConfigBuilderPage = lazy(() =>
  import("../pages/Product/ProductConfigBuilder/ProductConfigBuilderPage")
);
const ProductConfigOptionsPage = lazy(() =>
  import("../pages/Product/ProductConfigBuilder/ProductConfigOptionsPage")
);

const extractKeysFromMenu = (menu) => {
  let keys = [];

  const extract = (items) => {
    items?.forEach((item) => {
      if (item.key) {
        keys.push(item.key);
      }
      if (item["privatePaths"] && item["privatePaths"].length > 0) {
        keys.push(...item["privatePaths"]);
      }
      if (item.children && item.children.length > 0) {
        extract(item.children);
      }
    });
  };

  extract(menu);

  return keys;
};

const RoutesConfig = (props) => {
  const { userDetails, currentURL } = useAuth();

  let menuKeys = [];
  let firstMenuItemPath = null;

  if (userDetails) {
    menuKeys = extractKeysFromMenu(userDetails?.menu?.items || []);
    firstMenuItemPath =
      userDetails?.menu?.items?.[0]?.children.length > 0
        ? userDetails?.menu?.items?.[0]?.children?.[0]?.key
        : userDetails?.menu?.items?.[0]?.key;
  }

  const specialRoutes = ["*", "/user/self/detail", "/take-a-tour"];

  const routesConfig = [
    { path: "/customer/list", component: CustomerListPage },
    { path: "/customer/import-customer", component: ImportCustomerPage },
    { path: "/customer/import-details", component: ImportEnquiryDetailsPage },
    { path: "/customer/createNewAccount", component: ManageCustomerAccount },
    { path: "/customer/account/detail", component: CustomerEnquiry },
    { path: "/inventory/product/all", component: Products },
    {
      path: "/inventory/product/product-builder",
      component: ProductConfigBuilderPage,
    },
    {
      path: "/inventory/product/product-builder/options",
      component: ProductConfigOptionsPage,
    },
    { path: "/user", component: UserDetails },
    { path: "/user/role/list", component: Role },
    { path: "/organization/enquiry", component: OrganizationEnquiry },
    { path: "/order/details", component: OrderQuoteCreate },
    { path: "/order/management", component: OrderManagement },
    { path: "/settings/order/view/configuration", component: OrderSettings },
    { path: "/order/sales-view", component: SalesView },
    {
      path: "/product-group/configure-group-status",
      component: ConfigureProductGroupPage,
    },
    { path: "/orderManagement/details", component: OrderDetails },
    { path: "/import-orders", component: ImportOrdersPage },
    { path: "/user/self/detail", component: UserProfile },
    { path: "/production/capacity", component: CapacityPage },
    { path: "/production/schedule", component: ProductionSchedule },
    { path: "/take-a-tour", component: TourPage },
    { path: "/ticket", component: TicketPage },
    { path: "/", component: Dashboard },
    { path: "*", component: PageNotFound },
  ];

  if (!userDetails) {
    return (
      <Routes>
        <Route
          path="/login"
          element={
            <SuspenseWrapper>
              <LoginPage />
            </SuspenseWrapper>
          }
        />
        <Route
          path="*"
          element={
            <SuspenseWrapper>
              <LoginPage />
            </SuspenseWrapper>
          }
        />
      </Routes>
    );
  }

  return (
    <Routes>
      {!menuKeys.includes("/") && firstMenuItemPath && (
        <>
          {console.log("render")}
          <Route
            path="/"
            element={<Navigate to={firstMenuItemPath} replace />}
          />
        </>
      )}

      {routesConfig.map((route) =>
        menuKeys.includes(route.path) || specialRoutes.includes(route.path) ? (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PrivateRoute>
                <SuspenseWrapper>
                  <route.component />
                </SuspenseWrapper>
              </PrivateRoute>
            }
          />
        ) : null
      )}
    </Routes>
  );
};

export default RoutesConfig;

const SuspenseWrapper = ({ children }) => {
  const { isDarkMode } = useDarkMode();

  return (
    <Suspense
      fallback={
        <div
          className={`flex items-center justify-center h-screen-190px ${
            isDarkMode ? "!bg-[#1b1d1e]" : ""
          }`}
        >
          <Spin indicator={<LoadingOutlined />} />
        </div>
      }
    >
      {children}
    </Suspense>
  );
};
